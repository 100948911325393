.my-custom-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; /* Adjust width as needed */
  padding: 20px; /* Adjust padding as needed */
  font-size: 18px; /* Adjust text size as needed */
}

.my-custom-modal .react-confirm-alert-button-group button {
  font-size: 16px; /* Adjust button text size */
  padding: 10px 20px; /* Adjust button padding */
}
